import React from "react";
import dedaBackground from "../../assets/img/background.jpg";
import iflowbg from "../../assets/img/brand/iflow.png";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      dict: {},
      email: '',
      password: '',
      platform: '',
      erroremail: false,
      errorpass: false,
      auth: ''
    };
  };

  setErrorEmail(err) {
    this.state.erroremail = err;
    this.setState({
      errormail: err
    });
  }

  setErrorPass(err) {
    this.state.errorpass = err;
    this.setState({
      errorpass: err
    });
  }

  async loginUser(credentials) {
    console.log(credentials);
    return fetch(process.env.REACT_APP_API_LINK + "/api/auth/token/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json())
      .then((result) => {
        console.log(result);
        return result;
      });
  }

  handleSubmit = async e => {
    // console.log(email);
    // console.log(password);
    let email = this.state.email;
    let password = this.state.password;
    let platform = this.state.platform;

    e.preventDefault();
    console.log(1);
    const auth = await this.loginUser({
      email, password, platform
    });

    if (auth.detail == "Incorrect username or password") {
      console.log("Incorrect email and/or password");
      this.setErrorEmail(true);
    }
    // The below does not work

    if (auth.errors) {
      this.setErrorEmail(false);
      this.setErrorPass(false);
      if (auth.errors == 'Email not exists') {
        alert('caught wrong email');
        this.setErrorEmail(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
      if (auth.errors == 'Password Incorrect') {
        alert('caught wrong pass');
        this.setErrorPass(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
    };
    if (auth.access_token) {
      this.props.setToken(String(auth.access_token));
      console.log(auth.access_token);
      window.location.replace("/dashboardBasic/index");
    };

  }

  setEmail(email) {
    this.state.email = email;
    this.setState({
      email: this.state.email
    });
  }

  setPassword(password) {
    this.state.password = password;
    this.setState({
      password: this.state.password
    });
  }

  render() {
    return (
      <div className="main-content" style={{ backgroundImage: `url(${dedaBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed", height: "100vh" }}>
        <AuthNavbar />
        <div className="header py-7 py-lg-8" ></div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7" >
              <Card className="shadow border-0" style={{ backgroundColor: "rgba(255,255,255, 0.95)" }}>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={this.handleSubmit}>
                    <FormGroup className="mb-3">
                      <img src={iflowbg} className="center-block" style={{ width: '250px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                      <h2 style={{ textAlign: 'center', fontWeight: '300' }}>Intelliflow Core</h2> <br />
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" onChange={e => this.setEmail(e.target.value)} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Κωδικός" type="password" onChange={e => this.setPassword(e.target.value)} />
                      </InputGroup>
                    </FormGroup>
                    {this.state.erroremail ? <p style={{ color: 'orange' }}>To email ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.</p> : <></>}
                    {this.state.errorpass ? <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="text" style={{ color: 'red' }} /> : <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="hidden" style={{ color: 'red' }} />}

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Σύνδεση
                      </Button>

                      <Button href={"/signup"} className="my-4" color="info">
                        Γρήγορη Εγγραφή
                      </Button>
                    </div>
                  </Form>
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <small style={{ color: 'black', display: 'block', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>Ξεχάσατε τον κωδικό σας ;</small>
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

export default Login;