import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import DashboardIndex from "views/examples/dashboard/Index.js";
import SignUp from "views/examples/signup.js";
import Flows from "views/examples/dashboard/core/Flows.js";
import Flow from "views/examples/dashboard/core/Flow.js";
// import TestFlow from "views/examples/dashboard/TestFlow.js";
import Step from "views/examples/dashboard/core/Step.js";
// import newField from "views/examples/dashboard/newField.js";
import NewInstance from "views/examples/dashboard/instances/newInstance.js";
import Instance from "views/examples/dashboard/instances/Instance.js";
import SystemSettings from "views/examples/dashboard/settings/SystemSettings.js";
import Statistics from "views/examples/dashboard/statistics/Statistics.js";
import Maps from "views/examples/dashboard/maps/Maps.js";
import Templates from "views/examples/dashboard/templates/Templates.js";
import Template from "views/examples/dashboard/templates/Template.js";
import UserRoles from "views/examples/dashboard/users/UserRoles.js";
import UserRole from "views/examples/dashboard/users/UserRole.js";
// import DynamicFlow from "views/examples/dashboard/core/DynamicFlow.js";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: DashboardIndex,
    layout: "/basicLayout"
  },
  {
    path: "/flows",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: Flows,
    layout: "/basicLayout"
  },
  // {
  //   path: "/newflow",
  //   name: "Νέα Ροή",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: DynamicFlow,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/newField",
  //   name: "Προσθήκη νέου πεδίου",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: newField,
  //   layout: "/basicLayout"
  // },
  {
    path: "/newinstance",
    name: "Κατοχύρωση Νέας",
    icon: "ni ni-circle-08 text-pink",
    component: NewInstance,
    layout: "/basicLayout"
  },
  {
    path: "/flow/:id",
    name: "Επεξεργασία Ροής",
    icon: "ni ni-circle-08 text-pink",
    component: Flow,
    layout: "/basicLayout"
  },
  // {
  //   path: "/testflow/:id",
  //   name: "Επεξεργασία Ροής",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: TestFlow,
  //   layout: "/basicLayout"
  // },
  {
    path: "/step/:id",
    name: "Επεξεργασία Βήματος",
    icon: "ni ni-circle-08 text-pink",
    component: Step,
    layout: "/basicLayout"
  },
  // {
  //   path: "/result/:id",
  //   name: "Αποτέλεσμα Κατοχύρωσης",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: NewResult,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/instances",
  //   name: "Αιτήσεις",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicInstances,
  //   layout: "/basicLayout"
  // },
  {
    path: "/instance/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: Instance,
    layout: "/basicLayout"
  },
  // {
  //   path: "/details/:id",
  //   name: "Αίτηση",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicDetails,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/addcomment/:id",
  //   name: "Αρχική",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: NewComment,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/comments/:id",
  //   name: "Αρχική",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicComments,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/documents",
  //   name: "Αρχική",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicDocuments,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/profile",
  //   name: "Αρχική",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicProfile,
  //   layout: "/basicLayout"
  // },
  // {
  //   path: "/settings",
  //   name: "Αρχική",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: MechanicSettings,
  //   layout: "/basicLayout"
  // },
  {
    path: "/statistics",
    name: "Στατιστικά",
    icon: "ni ni-circle-08 text-pink",
    component: Statistics,
    layout: "/basicLayout"
  },
  {
    path: "/maps",
    name: "Χάρτες",
    icon: "ni ni-circle-08 text-pink",
    component: Maps,
    layout: "/basicLayout"
  },
  {
    path: "/templates",
    name: "Πρότυπα Εγγράφων",
    icon: "ni ni-circle-08 text-pink",
    component: Templates,
    layout: "/basicLayout"
  },
  {
    path: "/template/:id",
    name: "Πρότυπο Εγγράφου",
    icon: "ni ni-circle-08 text-pink",
    component: Template,
    layout: "/basicLayout"
  },
  {
    path: "/roles",
    name: "Ρόλοι Χρηστών",
    icon: "ni ni-circle-08 text-pink",
    component: UserRoles,
    layout: "/basicLayout"
  },
  {
    path: "/role/:id",
    name: "Ρόλος Χρήστη",
    icon: "ni ni-circle-08 text-pink",
    component: UserRole,
    layout: "/basicLayout"
  },
  {
    path: "/system",
    name: "Ρυθμίσεις Συστήματος",
    icon: "ni ni-circle-08 text-pink",
    component: SystemSettings,
    layout: "/basicLayout"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth"
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: "ni ni-key-25 text-info",
    component: SignUp,
    layout: "/auth"
  },
];

export default routes;
