import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment, { defineLocale } from "moment";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import 'moment/locale/el';
import Accordion from 'react-bootstrap/Accordion';
import "../../../../assets/css/modals.css";
import ReactTooltip from 'react-tooltip';

class Flow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            userDetails: [],
            instances: [],
            dict: {},
            steps: [],
            show: false,
            show2: false,
            show3: false,
            stepName: false,
            stepDescription: false,
            stepPriority: false,
            workflowId: '',
            stepActionName: false,
            stepActionDescription: false,
            stepActionPriority: false,
            stepActionKind: "text",
            stepActionRequired: true,
            stepActionTarget: false,
            tempStep: '',
            stepActionData: []
        };
    };

    async componentDidMount() {
        let flow_id = window.location.pathname.split('/')[3];
        this.state.workflowId = flow_id;

        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);

                    if (result.PersonRole != 'Master')
                        this.props.history.goBack();

                    this.setState({
                        userDetails: result
                    });
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + flow_id + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        steps: result
                    })
                })
        };
    }

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    setShow2(boolean, stepId) {
        this.setState({
            show2: boolean,
            tempStep: stepId
        });
    }

    setShow3(boolean, stepActionId) {

        if (boolean) {
            fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/current/" + stepActionId + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);

                    this.setState({
                        stepActionData: result,
                        show3: boolean
                    });
                })
        }
        else {
            this.setState({
                stepActionData: [],
                show3: boolean
            });
        }

    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        });
    }

    handleFieldChange(evt) {
        this.state.stepActionData[evt.target.name] = evt.target.value;
        console.log(this.state.stepActionData[evt.target.name]);
    }

    isFormValid() {
        const { stepName, stepDescription } = this.state;
        return stepName && stepDescription;
    }

    isFormValidnewField() {
        const { stepActionName, stepActionDescription, stepActionKind, stepActionRequired, stepActionTarget } = this.state;
        return stepActionName && stepActionDescription && stepActionTarget;
    }

    handleSubmit() {
        console.log(this.state.flowName, this.state.flowDescription);

        fetch(process.env.REACT_APP_API_LINK + "/api/steps/new/", {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'name': this.state.stepName,
                'description': this.state.stepDescription,
                // 'priority': this.state.stepPriority,
                'workflowId': this.state.workflowId,
            })
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);

                this.setShow(false);

                fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        this.setState({
                            steps: result,
                            stepName: false,
                            stepDescription: false,
                            stepPriority: false,
                            stepActionName: false,
                            stepActionDescription: false,
                            stepActionPriority: false,
                            stepActionKind: "text",
                            stepActionRequired: true,
                            stepActionTarget: false,
                            tempStep: ''
                        })
                    })
            })
    }

    handleSubmitNewField() {
        console.log(this.state.stepActionName, this.state.stepActionDescription, this.state.stepActionKind, this.state.stepActionRequired, this.state.stepActionPriority, this.state.stepActionTarget);

        fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/new/", {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'name': this.state.stepActionName,
                'description': this.state.stepActionDescription,
                // 'priority': this.state.stepActionPriority,
                'type': this.state.stepActionKind,
                'target': this.state.stepActionTarget,
                'required': this.state.stepActionRequired,
                'stepId': this.state.tempStep,
            })
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);

                this.setShow2(false);

                fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        this.setState({
                            steps: result,
                            stepName: false,
                            stepDescription: false,
                            stepPriority: false,
                            stepActionName: false,
                            stepActionDescription: false,
                            stepActionPriority: false,
                            stepActionKind: "text",
                            stepActionRequired: true,
                            stepActionTarget: false,
                            tempStep: ''
                        })
                    })
            })
    }

    deleteStep = (stepId, workflowId) => {
        if (window.confirm('Είστε Σίγουρη/Σίγουρος?')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/steps/delete/" + workflowId + '/' + stepId + "/", {
                method: 'delete',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            console.log(result);
                            this.setState({
                                steps: result
                            })
                        })
                })
        }
    }

    deleteStepAction = (stepactionId, stepId) => {
        if (window.confirm('Είστε Σίγουρη/Σίγουρος?')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/delete/" + stepId + '/' + stepactionId + "/", {
                method: 'delete',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            console.log(result);
                            this.setState({
                                steps: result
                            })
                        })
                })
        }

    }

    changeFieldOrder = (direction, fieldId, stepId) => {
        console.log(direction, fieldId);
        fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/changeorder/" + stepId + '/' + fieldId + '/' + (direction ? 1 : 0) + '/', {
            method: 'put',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        this.setState({
                            steps: result
                        })
                    })
            })
    }

    changeFieldData = (stepActionId) => {
        fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/changefield/" + stepActionId + '/', {
            method: 'put',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'data': [this.state.stepActionData],
            })
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);

                this.setShow3(false,null);
                fetch(process.env.REACT_APP_API_LINK + "/api/steps/all/" + this.state.workflowId + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        this.setState({
                            steps: result
                        })
                    })
            })
    }

    render() {
        return (
            <>
                {this.state.userDetails.PersonRole == 'Master' ?
                    <>
                        <GenericHeader />

                        <Container className="mt--7" fluid>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Διαμόρφωση Βημάτων</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Button
                                                        color="success"
                                                        // href={"/basicLayout/newStep"}
                                                        onClick={() => this.setShow(true)}
                                                        size="sm"
                                                    >
                                                        Προσθήκη Βήματος

                                                    </Button>
                                                </div>

                                            </Row>

                                            <Accordion defaultActiveKey={0}>
                                                {this.state.steps.map((dynamicData) =>
                                                    <Container className="mt-2" fluid>
                                                        <Row className="mt-5">
                                                            <Col className="order-xl-1" xl="12">
                                                                <Card className="bg-secondary shadow">
                                                                    <CardHeader className="bg-white border-0">
                                                                        <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
                                                                            <Row className="align-items-center">
                                                                                <Col xs="10">
                                                                                    <h3 className="mb-0">{dynamicData.step.Status}. {dynamicData.step.Name}</h3>
                                                                                </Col>
                                                                                <Button
                                                                                    color="danger"
                                                                                    onClick={() => { this.deleteStep(dynamicData.step.Id, this.state.workflowId) }}
                                                                                    size="sm"
                                                                                    title="Διαγραφή"
                                                                                >
                                                                                    <i className="fas fa-trash" />
                                                                                </Button>
                                                                                <Col>

                                                                                </Col>
                                                                                {/* {dynamicData.step.EditPermission.includes(this.state.userDetails.PersonRole) ?
                                                                                    <>

                                                                                    </>
                                                                                    : <></>} */}
                                                                            </Row>
                                                                        </Accordion.Toggle>
                                                                    </CardHeader>
                                                                    <Accordion.Collapse eventKey={dynamicData.step.Status}>
                                                                        <CardBody>
                                                                            <Form id={dynamicData.step.Id}>
                                                                                <div className="">
                                                                                    <Row >

                                                                                        <Col lg="12">
                                                                                            <Col lg="12">
                                                                                                <Card>
                                                                                                    <CardBody>
                                                                                                        <div className="col text-right">
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                onClick={() => this.setShow2(true, dynamicData.step.Id)}
                                                                                                                size="sm"
                                                                                                                title="Προσθήκη Πεδίου"
                                                                                                            >
                                                                                                                <i className="fas fa-plus" />
                                                                                                                &nbsp;Προσθήκη Πεδίου
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                color="warning"
                                                                                                                onClick={() => console.log('click')}
                                                                                                                size="sm"
                                                                                                                title="Προσθήκη Mail Action"
                                                                                                            >
                                                                                                                <i className="fas fa-plus" />
                                                                                                                &nbsp;Προσθήκη Mail Action
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                        {dynamicData.actions.map((dynamicActions) =>
                                                                                                            <> {dynamicData.step.ViewPermission.includes(this.state.userDetails.PersonRole) ?
                                                                                                                <>
                                                                                                                    {dynamicActions.Type == 'text' ?
                                                                                                                        <>
                                                                                                                            <Row>
                                                                                                                                <Col lg="10">

                                                                                                                                    <label
                                                                                                                                        className="form-control-label"
                                                                                                                                        htmlFor={dynamicActions.Id}
                                                                                                                                    >
                                                                                                                                        {dynamicActions.Name}
                                                                                                                                    </label>
                                                                                                                                    &nbsp;
                                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-text' + dynamicActions.Id} aria-hidden="true"></i>
                                                                                                                                    <ReactTooltip id={'info-text' + dynamicActions.Id} type='info'>
                                                                                                                                        <span>{dynamicActions.Description}</span>
                                                                                                                                    </ReactTooltip>
                                                                                                                                    <Input
                                                                                                                                        className="form-control-alternative"
                                                                                                                                        defaultValue={dynamicActions.Name}
                                                                                                                                        id={dynamicActions.Id}
                                                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                                                        type='text'
                                                                                                                                        // className="w-25"
                                                                                                                                        disabled={true}

                                                                                                                                    // onChange={evt => this.updateInputValue(evt)}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                                <Col lg="2">
                                                                                                                                    <Button
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Πληροφορίες"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-info" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="danger"
                                                                                                                                        onClick={() => { this.deleteStepAction(dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Μετακίνηση"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-trash" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="warning"
                                                                                                                                        onClick={() => this.setShow3(true, dynamicActions.Id)}
                                                                                                                                        size="sm"
                                                                                                                                        title="Επεξεργασία"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-pencil-alt" />
                                                                                                                                    </Button>
                                                                                                                                    {dynamicActions.Order ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(true, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-up" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                    {dynamicActions.Order != dynamicData.count - 1 && dynamicData.count ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(false, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-down" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            <br />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <></>}
                                                                                                                    {dynamicActions.Type == 'file' ?
                                                                                                                        <>
                                                                                                                            <Row>
                                                                                                                                <Col lg="10">
                                                                                                                                    <label
                                                                                                                                        className="form-control-label"
                                                                                                                                        htmlFor={dynamicActions.Id}
                                                                                                                                    >
                                                                                                                                        {dynamicActions.Name}
                                                                                                                                    </label>
                                                                                                                                    <br />
                                                                                                                                    <label className="btn btn-primary">
                                                                                                                                        Επιλογή αρχείου
                                                                                                                                    </label>

                                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicActions.Id} aria-hidden="true"></i>
                                                                                                                                    <ReactTooltip id={'info-form-control-alternative' + dynamicActions.Id} type='info'>
                                                                                                                                        <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                                                                                                                        <div id="imageattooltip"></div>
                                                                                                                                    </ReactTooltip>
                                                                                                                                    <Input
                                                                                                                                        className="form-control-alternative"
                                                                                                                                        // defaultValue={ dynamicActions.data.Data }
                                                                                                                                        // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                                                        style={{ visibility: "hidden" }}
                                                                                                                                        type={dynamicActions.Type}
                                                                                                                                        disabled={true}
                                                                                                                                    // onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                                                    />
                                                                                                                                    <Table className="align-items-center table-flush" responsive>
                                                                                                                                        <thead className="thead-light">
                                                                                                                                            <tr>
                                                                                                                                                <th scope="col">Ονομα Αρχειου</th>
                                                                                                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                                                                                                <th scope="col">Ενεργειες</th>
                                                                                                                                            </tr>
                                                                                                                                        </thead>
                                                                                                                                        <tbody>
                                                                                                                                            {/* {
                                                                                                                                        this.state.files.map((dynamicFiles) =>
                                                                                                                                            <>
                                                                                                                                                {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>{dynamicFiles.FileName}</td>
                                                                                                                                                            <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                                                                                                            <td>
                                                                                                                                                                <Button
                                                                                                                                                                    color="primary"
                                                                                                                                                                    href={dynamicFiles.Link}
                                                                                                                                                                    target="_blank"
                                                                                                                                                                    size="sm"
                                                                                                                                                                    title="Προβολή"
                                                                                                                                                                >
                                                                                                                                                                    <i className="fas fa-eye" />
                                                                                                                                                                </Button>
                                                                                                                                                                {console.log(dynamicFiles)}
                                                                                                                                                                <Button
                                                                                                                                                                    color="danger"
                                                                                                                                                                    onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                                                    size="sm"
                                                                                                                                                                    title="Διαγραφή"
                                                                                                                                                                >
                                                                                                                                                                    <i className="fas fa-trash" />
                                                                                                                                                                </Button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </> : <></>
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )} */}
                                                                                                                                        </tbody>
                                                                                                                                    </Table>
                                                                                                                                </Col>
                                                                                                                                <Col lg="2">
                                                                                                                                    <Button
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Πληροφορίες"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-info" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="danger"
                                                                                                                                        onClick={() => { this.deleteStepAction(dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Μετακίνηση"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-trash" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="warning"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Επεξεργασία"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-pencil-alt" />
                                                                                                                                    </Button>
                                                                                                                                    {dynamicActions.Order ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(true, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-up" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                    {dynamicActions.Order != dynamicData.count - 1 && dynamicData.count ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(false, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-down" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                </Col>
                                                                                                                            </Row>

                                                                                                                            {/* </> } */}
                                                                                                                            <br />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {dynamicActions.Type == 'textarea' ?
                                                                                                                        <>
                                                                                                                            {/* &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                            <ReactTooltip id={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                                <span>{dynamicActions.Description}</span>
                                                                                                                            </ReactTooltip> */}
                                                                                                                            <Row>
                                                                                                                                <Col lg="10">

                                                                                                                                    <label
                                                                                                                                        className="form-control-label"
                                                                                                                                        htmlFor={dynamicActions.Id}
                                                                                                                                    >
                                                                                                                                        {dynamicActions.Name}
                                                                                                                                    </label>
                                                                                                                                    &nbsp;
                                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicActions.Id} aria-hidden="true"></i>
                                                                                                                                    <ReactTooltip id={'info-textarea' + dynamicActions.Id} type='info'>
                                                                                                                                        <span>{dynamicActions.Description}</span>
                                                                                                                                    </ReactTooltip>
                                                                                                                                    <Input
                                                                                                                                        className="form-control-alternative"
                                                                                                                                        defaultValue={dynamicActions.Name}
                                                                                                                                        // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                                                        type='textarea'
                                                                                                                                        disabled={true}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                                <Col lg="2">
                                                                                                                                    <Button
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Πληροφορίες"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-info" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="danger"
                                                                                                                                        onClick={() => { this.deleteStepAction(dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Μετακίνηση"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-trash" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="warning"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Επεξεργασία"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-pencil-alt" />
                                                                                                                                    </Button>
                                                                                                                                    {dynamicActions.Order ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(true, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-up" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                    {dynamicActions.Order != dynamicData.count - 1 && dynamicData.count ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(false, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-down" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            <br />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <></>}
                                                                                                                    {dynamicActions.Type == 'number' ?
                                                                                                                        <>
                                                                                                                            <Row>
                                                                                                                                <Col lg="10">

                                                                                                                                    <label
                                                                                                                                        className="form-control-label"
                                                                                                                                        htmlFor={dynamicActions.Id}
                                                                                                                                    >
                                                                                                                                        {dynamicActions.Name}
                                                                                                                                    </label>
                                                                                                                                    &nbsp;
                                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-text' + dynamicActions.Id} aria-hidden="true"></i>
                                                                                                                                    <ReactTooltip id={'info-text' + dynamicActions.Id} type='info'>
                                                                                                                                        <span>{dynamicActions.Description}</span>
                                                                                                                                    </ReactTooltip>
                                                                                                                                    <Input
                                                                                                                                        className="form-control-alternative"
                                                                                                                                        defaultValue={dynamicActions.Name}
                                                                                                                                        id={dynamicActions.Id}
                                                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                                                        type='number'
                                                                                                                                        // className="w-25"
                                                                                                                                        disabled={true}

                                                                                                                                    // onChange={evt => this.updateInputValue(evt)}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                                <Col lg="2">
                                                                                                                                    <Button
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Πληροφορίες"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-info" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="danger"
                                                                                                                                        onClick={() => { this.deleteStepAction(dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Μετακίνηση"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-trash" />
                                                                                                                                    </Button>
                                                                                                                                    <Button
                                                                                                                                        color="warning"
                                                                                                                                        onClick={() => { alert(2) }}
                                                                                                                                        size="sm"
                                                                                                                                        title="Επεξεργασία"
                                                                                                                                        style={{ marginTop: '40px' }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-pencil-alt" />
                                                                                                                                    </Button>
                                                                                                                                    {dynamicActions.Order ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(true, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-up" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                    {dynamicActions.Order != dynamicData.count - 1 && dynamicData.count ?
                                                                                                                                        <>
                                                                                                                                            <Button
                                                                                                                                                color="success"
                                                                                                                                                onClick={() => { this.changeFieldOrder(false, dynamicActions.Id, dynamicData.step.Id) }}
                                                                                                                                                size="sm"
                                                                                                                                                title="Μετακίνηση"
                                                                                                                                                style={{ marginTop: '40px' }}
                                                                                                                                            >
                                                                                                                                                <i className="fas fa-long-arrow-alt-down" />
                                                                                                                                            </Button>
                                                                                                                                        </> : <></>
                                                                                                                                    }
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            <br />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <></>}


                                                                                                                </>
                                                                                                                : <></>}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </CardBody>
                                                                                                </Card>
                                                                                            </Col>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Form>
                                                                        </CardBody>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                )}
                                            </Accordion>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>

                            <div className='push'></div>
                            <Modal
                                show={this.state.show}
                                onHide={() => this.setShow(false)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΒΗΜΑΤΟΣ
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-step-name"
                                                        >
                                                            Όνομα Βήματος
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-step-name"
                                                            name="stepName"
                                                            placeholder="Όνομα Βήματος"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-step-description"
                                                        >
                                                            Περιγραφή Βήματος
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-step-description"
                                                            placeholder="Περιγραφή Βήματος"
                                                            name="stepDescription"
                                                            type="textarea"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </div>

                                    </Form>
                                    <div className="col text-right">
                                        <Button
                                            color="success"
                                            onClick={() => this.handleSubmit()}
                                            size="sm"
                                            disabled={!this.isFormValid()}
                                        >
                                            Ολοκλήρωση

                                        </Button>

                                    </div>
                                </Modal.Body>
                            </Modal>


                            <Modal
                                show={this.state.show2}
                                onHide={() => this.setShow2(false)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΠΕΔΙΟΥ
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-stepAction-name"
                                                        >
                                                            Όνομα Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-stepAction-name"
                                                            name="stepActionName"
                                                            placeholder="Όνομα Πεδίου"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-stepAction-description"
                                                        >
                                                            Περιγραφή Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-stepAction-description"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            name="stepActionDescription"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-stepAction-priority"
                                                        >
                                                            Προτεραιότητα Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-stepAction-priority"
                                                            placeholder="Προτεραιότητα Πεδίου"
                                                            name="stepActionPriority"
                                                            type="number"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Είδος Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-first-name"
                                                            type="select"
                                                            name="stepActionKind"
                                                            onChange={evt => this.handleChange(evt)}
                                                        >
                                                            <option value="text">Κείμενο</option>
                                                            <option value="file">Αρχείο</option>
                                                            <option value="textarea">Πεδίο μεγάλου κειμένου</option>
                                                            <option value="number">Αριθμός</option>
                                                            <option value="rating">Κριτική</option>
                                                            <option value="map-pin">Πινέζα σε Χάρτη</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Κλειδί Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            name="stepActionTarget"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Τύπος Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-first-name"
                                                            name="stepActionRequired"
                                                            type="select"
                                                            onChange={evt => this.handleChange(evt)}
                                                        >
                                                            <option value={true}>Υποχρεωτικό</option>
                                                            <option value={false}>Μη Υποχρεωτικό</option>

                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Form>
                                    <div className="col text-right">
                                        <Button
                                            color="success"
                                            onClick={() => this.handleSubmitNewField()}
                                            size="sm"
                                            disabled={!this.isFormValidnewField()}
                                        >
                                            Ολοκλήρωση

                                        </Button>

                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal
                                show={this.state.show3}
                                onHide={() => this.setShow3(false, null)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        ΕΠΕΞΕΡΓΑΣΙΑ ΠΕΔΙΟΥ
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form>
                                        {/* <h6 className="heading-small text-muted mb-4">
                                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                                        </h6> */}
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-stepAction-edit-name"
                                                        >
                                                            Όνομα Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-stepAction-edit-name"
                                                            name="Name"
                                                            defaultValue={this.state.stepActionData.Name}
                                                            type="text"
                                                            onChange={evt => this.handleFieldChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-stepAction-edit-description"
                                                        >
                                                            Περιγραφή Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-stepAction-edit-description"
                                                            defaultValue={this.state.stepActionData.Description}
                                                            name="Description"
                                                            type="text"
                                                            onChange={evt => this.handleFieldChange(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </div>

                                    </Form>
                                    <div className="col text-right">
                                        <Button
                                            color="primary"
                                            onClick={() => this.changeFieldData(this.state.stepActionData.Id)}
                                            size="sm"
                                        // disabled={!this.isFormValid()}
                                        >
                                            Αποθήκευση

                                        </Button>

                                    </div>
                                </Modal.Body>
                            </Modal>


                        </Container>
                    </>
                    :
                    <>
                    </>
                }


            </>
        );
    }
}

export default Flow;