import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import Map from './instances/actionTypes/Map';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import "../../../assets/css/datatables.css";

// import Rating from "./instances/actionTypes/Rating";

class DashboardIndex extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      dict: {},
    };
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
        method: 'get',
        headers: new Headers({
          'Authorization' : 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.json())
      .then((result) => {
          console.log(result);
          this.setState({ 
            userDetails : result
          });
      })
    
      await axios.get(process.env.REACT_APP_API_LINK + "/api/instances/all/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
      .then((res) => {
        const instances2 = res.data;
        instances2.forEach(function (part, index) {
        this[index].NewStatus = this[index].Status == 1 ? "1. Νέο Αίτημα" : this[index].Status == 2 ? "2. Αίτημα Προς Επεξεργασία" : this[index].Status == 3 ? "3. Αίτημα Ολοκληρώθηκε" : this[index].Status == 4 ? "4. Σύνταξη Προσφοράς" : this[index].Status == 5 ? "5. Υπογραφή Σύμβασης" : this[index].Status == 6 ? "6. Μηχ.Ολοκλήρωση & Μελέτη εσωτ. εγκατ." : this[index].Status == 7 ? "7. Σύμβαση με Προμηθευτή" : this[index].Status == 8 ? "8. Ενεργοποίηση εσωτερικής εγκατάστασης" : this[index].Status == 9 ? "9. Φύλλο Καύσης" : this[index].Status == 10 ? "10. Έκδοση άδειας χρήσης" : "";
        this[index].Flow = this[index].SerialNumber == 1 ? "Αιτήματα Από Διοίκηση" : this[index].SerialNumber == 2 ? "Αιτήματα Προς Διοίκηση" : this[index].SerialNumber == 3 ? "Αιτήματα IT" : "";
        this[index].Buttons =
            <React.Fragment>
                {/* <Button
                  color="primary"
                  href={"/admin/details/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button> */}
                <Button
                    color="primary"
                    href={"/basicLayout/instance/" + this[index].Id}
                    size="sm"
                >
                    Βήματα Αίτησης
                </Button>
            </React.Fragment>
        console.log(index)
        }, instances2);
        this.datas = {
          columns: [
              {
                label: 'Αριθμος Αιτησης ▼',
                field: 'SerialNumber',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Κατασταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Τελ. Ενημερωση ▼',
                field: 'Comments',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ροη',
                field: 'Flow',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Μεταβαση ▼',
                field: 'Buttons',
                sort: 'asc',
                width: 150
              }
            ],
            rows: res.data
        };
    
        const instances = res.data;
        this.setState({ instances });
        console.log(instances);
    })
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{marginBottom: '20px'}}>
                    <div className="col">
                      <h3 className="mb-0">Αρχική</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="success"
                        href={"/basicLayout/newinstance"}
                        size="sm"
                      >
                        Καταχώρηση Νέας Εργασίας
                        
                      </Button>
                    </div>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip="true"
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    nodatacomponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    snext="Suivant"
                    // nodatacomponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                  /> 
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Rating />
          </Row> */}
          
          
          <div className='push'></div>
        </Container>
      </>
    );
  }
}

export default DashboardIndex;
