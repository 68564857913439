import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';
import Modal from 'react-modal';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

import File from "./actionTypes/File";
import ReportIssue from "./ReportIssue";

class Instance extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                error: null,
                loading: true,
                instance: [],
                workflow: [],
                user: [],
                logged_user: [],
                steps: [],
                files: [],
                enabled: true,
                fileUpload: null,
                removeActions: 0,
            };
        }

        async componentDidMount() {
            const handle = this.props.match.params.id;
            console.log(handle);

            const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/instances/instance/" + handle + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                .then(res => {
                    this.setState({
                        instance: res.data.instance,
                        workflow: res.data.workflow,
                        user: res.data.user,
                        logged_user: res.data.logged_user,
                        steps: res.data.stepsInfused,
                        fileUpload: null,
                    });
                    console.log(res);
                })

            const result2 = await axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + handle + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        files: res.data
                    });
                    console.log(res);
                })
        };

        saveInstance = stepStatus => {
            console.log(this.state.steps);
            this.setState({ removeActions: 1 });

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/instances/update/' + this.state.instance.Id + "/", {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        }),
                        body: JSON.stringify(this.state.steps[stepStatus - 1])
                    })
                    .then(data => {
                        console.log(data);
                        window.location.reload(false);
                    })
            }
        }

        submitInstance = stepStatus => {
            console.log(this.state.steps);
            this.setState({ removeActions: 1 });
    
            if (localStorage.getItem('token')) { 
                fetch(process.env.REACT_APP_API_LINK + '/api/instances/submit/' + this.state.instance.Id + '/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify(this.state.steps[stepStatus-1])
                })
                .then(data => {
                    console.log(data);
                    window.location.reload(false);
                })
            }
        }

        approveInstance = stepStatus => {
            console.log(this.state.steps);
            this.setState({ removeActions: 1 });

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/instances/approve/' + this.state.instance.Id + "/", {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        }),
                        body: JSON.stringify(this.state.steps[stepStatus - 1])
                    })
                    .then(data => {
                        console.log(data);

                        window.location.reload(false);
                    })
            }
        }

        assignInstance = stepStatus => {
            console.log(this.state.steps);

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/instances/assign/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'InstanceId': this.state.instance.Id,
                        'UserId': this.state.logged_user.Id
                    })
                })
                .then(data => {
                    console.log(data);

                    window.location.reload(false);
                })
            }
        }

        declineInstance = stepStatus => {
            console.log(this.state.steps);
            this.setState({ removeActions: 1 });

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/instances/decline/' + this.state.instance.Id + "/", {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        }),
                        body: JSON.stringify(this.state.steps[stepStatus - 1])
                    })
                    .then(data => {
                        console.log(data);

                        window.location.replace("/admin/details/" + this.state.instance.Id);
                    })
            }
        }

        dynamicCall(call) {
            console.log(call);
        }

        updateInputValue(evt) {
            // console.log(evt);
            var res = evt.target.id.split("/");
            let data = [...this.state.steps];


            var i;
            for (i = 0; i < (this.state.steps).length; i++) {
                if (this.state.steps[i].step.Id == res[0]) {
                    var j;
                    for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                        if (this.state.steps[i].actions[j].action.Id == res[1]) {
                            let tempAction = {...data[i].actions[j] };
                            
                            console.log(tempAction);
                            if (tempAction.action.Target == 'limit14') {
                                if ((evt.target.value).length > 14) {
                                    evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
                                }
                            }
                    
                            if (tempAction.action.Target == 'limit9') {
                                if ((evt.target.value).length > 9) {
                                    evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
                                }
                            }
                            tempAction.data.Data = evt.target.value;
                            data[i].actions[j] = tempAction;

                            this.setState({
                                steps: data
                            });
                        }
                    }
                }
            }
        }

        filesCallback = (files) => {
            this.setState({
                files: files
            })
        }

        render() {
            if (this.state.loading) {
                return (
                    <>
                        <GenericHeader />
                        <CommonLoading />
                    </>
                ) 
            } else {
                return (
                    <>
                        <InstanceHeader />
                        <div className="header pb-4" style={{background: "linear-gradient(to right, #a8e6ff, #095d80)"}}>
                            <Container fluid>
                                <div className="header-body">
                                    <Row>
                                        <Col lg="8" xl="12">
                                            <Card className="card-stats mb-4 mb-xl-0">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                            </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                Ροή Εργασίας
                                                                </span>
                                                            <CardBody>
                                                                <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Α/Α: &nbsp; </span> {this.state.instance.SerialNumber}/{new Date().getFullYear()}</Row>
                                                                <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Ημερομηνία Έναρξης: &nbsp; </span> {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                                <Row className="align-items-center"><br></br></Row>
                                                                <Row className="align-items-center">
                                                                    <Button
                                                                        color="primary"
                                                                        href={"/admin/details/" + this.state.instance.Id}
                                                                        size="sm"
                                                                    >
                                                                        Στοιχεία Αίτησης
                                                                    </Button>
                                                                </Row>
                                                            </CardBody>
                                                        </div>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>

                        <Accordion defaultActiveKey={this.state.instance.Status}>
                        { this.state.steps.map((dynamicData) =>
                            <Container className="mt-2" fluid>
                                <Row className="mt-5">
                                    <Col className="order-xl-1" xl="12">
                                        <Card className="bg-secondary shadow">
                                            <CardHeader className="bg-white border-0">
                                                <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
                                                    <Row className="align-items-center">
                                                        <Col xs="8">
                                                            <h3 className="mb-0">{dynamicData.step.Status}. {dynamicData.step.Name}</h3>
                                                        </Col>
                                                        {dynamicData.step.ViewPermission.includes(this.state.logged_user.PersonRole) ?
                                                            <>
                                                            {dynamicData.step.Status == this.state.instance.Status ? 
                                                                <>
                                                                    {this.state.instance.IsAssigned && (this.state.instance.UserAssignedId == this.state.logged_user.Id) ? 
                                                                        <>
                                                                            {this.state.removeActions ? 
                                                                                <> </>
                                                                            :
                                                                                <Col className="text-right" xs="4">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => { this.saveInstance(dynamicData.step.Status) }}
                                                                                        size="sm"
                                                                                    >
                                                                                        Αποθήκευση
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="success"
                                                                                        onClick={() => { this.approveInstance(dynamicData.step.Status) }}
                                                                                        size="sm"
                                                                                    >
                                                                                        Έγκριση
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        onClick={() => { this.declineInstance(dynamicData.step.Status) }}
                                                                                        size="sm"
                                                                                    >
                                                                                        Επανεξέταση
                                                                                    </Button>
                                                                                </Col>
                                                                            }
                                                                        </>
                                                                        :  <> {(!this.state.instance.IsAssigned) && this.state.logged_user.PersonRole != 'Client'?
                                                                                <Col className="text-right" xs="4">
                                                                                    <Button
                                                                                        color="success"
                                                                                        onClick={() => { this.assignInstance(dynamicData.step.Status) }}
                                                                                        size="sm"
                                                                                    >
                                                                                        Έναρξη Εργασίας
                                                                                    </Button>
                                                                                </Col>
                                                                            : <>{this.state.logged_user.PersonRole == 'Client' ?
                                                                                    <Col className="text-right" xs="4">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            onClick={() => { this.saveInstance(dynamicData.step.Status) }}
                                                                                            size="sm"
                                                                                        >
                                                                                            Αποθήκευση
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="success"
                                                                                            onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                                            size="sm"
                                                                                        >
                                                                                            Υποβολή
                                                                                        </Button>
                                                                                    </Col>
                                                                                    : <> </>
                                                                                } </>}
                                                                            </>
                                                                        }
                                                                </> : <> </>
                                                            }
                                                            </> : <> </>
                                                        } 
                                                    </Row>
                                                </Accordion.Toggle>
                                            </CardHeader>
                                            <Accordion.Collapse eventKey={dynamicData.step.Status}>
                                                <CardBody>
                                                    <Form id={dynamicData.step.Id} onSubmit={this.handleSubmit}>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                {this.state.enabled = dynamicData.step.Status === this.state.instance.Status, dynamicData.actions.map((dynamicActions) =>
                                                                    <> 
                                                                        { dynamicData.step.ViewPermission.includes(this.state.logged_user.PersonRole) ?
                                                                            <> 
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <label
                                                                                            className="form-control-label"
                                                                                            htmlFor="input-username"
                                                                                        >
                                                                                            {dynamicActions.action.Name}
                                                                                        </label>

                                                                                        { dynamicActions.action.Type == 'file' ? 
                                                                                            <>
                                                                                                <File
                                                                                                    key={dynamicActions.action.Id}
                                                                                                    dynamicData={dynamicData} 
                                                                                                    dynamicActions={dynamicActions} 
                                                                                                    files={this.state.files} 
                                                                                                    instance = {this.state.instance} 
                                                                                                    logged_user = {this.state.logged_user} 
                                                                                                    enabled = {this.state.enabled}
                                                                                                    filesCallback = {this.filesCallback}
                                                                                                />
                                                                                            </>
                                                                                        : <> 
                                                                                            { dynamicActions.action.Type == 'button' ?
                                                                                                <>
                                                                                                    {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) && this.state.instance.IsAssigned && (this.state.instance.UserAssignedId == this.state.logged_user.Id) ?
                                                                                                        <>
                                                                                                            <Col lg="6">
                                                                                                                <Button
                                                                                                                    color="primary"
                                                                                                                    onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                    size="lg"
                                                                                                                >
                                                                                                                    {dynamicActions.action.Description}
                                                                                                                </Button>
                                                                                                            </Col>

                                                                                                            {this.state.buttonCondition == dynamicData.step.Status ? 
                                                                                                                <p> {this.state.buttonMessage} </p>
                                                                                                            :   
                                                                                                                <> {this.state.buttonCondition == 5 ? 
                                                                                                                        <p>{this.state.buttonMessage}</p> 
                                                                                                                        : <></>
                                                                                                                    } 
                                                                                                                </> 
                                                                                                            }
                                                                                                        </> : <> </>
                                                                                                    }
                                                                                                </>
                                                                                            : <> 
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    defaultValue={ dynamicActions.data.Data }
                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                    type={dynamicActions.action.Type}
                                                                                                    disabled={!this.state.enabled}
                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                />
                                                                                              </>
                                                                                            }
                                                                                        </> }
                                                                                        <ReportIssue
                                                                                            dynamicData={dynamicData} 
                                                                                            dynamicActions={dynamicActions}
                                                                                            instance = {this.state.instance} 
                                                                                            logged_user = {this.state.logged_user} 
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </> 
                                                                        : <> </> } 
                                                                    </>
                                                                )}
                                                            </Row>
                                                        </div>
                                                    </Form>
                                                </CardBody>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                        </Accordion>
                    </>
                    );
                }
            }
        }
        
    export default Instance;        