import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import ReactTooltip from 'react-tooltip';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import "../../../../assets/css/modals.css";

class Flows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            userDetails: [],
            instances: [],
            dict: {},
            flows: [],
            show: false,
            flowName: false,
            flowDescription: false,
            flowPriority: false,
            startingDate: '',
            endingDate: ''
        };
    };

    async componentDidMount() {
        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);

                    if (result.PersonRole != 'Master')
                        this.props.history.goBack();

                    this.setState({
                        userDetails: result
                    });
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/flows/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        flows: result
                    })
                })
        };
    }

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        });
    }

    handleSubmit() {
        console.log(this.state.flowName, this.state.flowDescription);

        fetch(process.env.REACT_APP_API_LINK + "/api/flows/new/", {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'name': this.state.flowName,
                'description': this.state.flowDescription,
                'priority': this.state.flowPriority,
                'startingDate': this.state.startingDate,
                'endingDate': this.state.endingDate,
            })
        })
        .then(res => res.json())
        .then((result) => {
            console.log(result);

            this.setShow(false);

            fetch(process.env.REACT_APP_API_LINK + "/api/flows/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        flows: result
                    })
                })
        })
    }

    deleteFlow = (flowid) => {
        if (window.confirm('Είστε Σίγουρη/Σίγουρος?')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/flows/delete/" + flowid + "/", {
                method: 'delete',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    fetch(process.env.REACT_APP_API_LINK + "/api/flows/all/", {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {
                            console.log(result);
                            this.setState({
                                flows: result
                            })
                        })
                })
        }
    }

    isFormValid() {
        const { flowName} = this.state;
        return flowName;
    }

    render() {
        return (
            <>
                {this.state.userDetails.PersonRole == 'Master' ?
                    <>
                        <GenericHeader />

                        <Container className="mt--7" fluid>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Διαμόρφωση Ροών Εργασίας</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Button
                                                        color="success"
                                                        onClick={() => this.setShow(true)}
                                                        size="sm"
                                                    >
                                                        Προσθήκη Ροής

                                                    </Button>
                                                    {/* <Button
                                                        color="warning"
                                                        // onClick={() => this.setShow(true)}
                                                        href={"/basicLayout/newflow/"}
                                                        size="sm"
                                                    >
                                                        Προσθήκη Ροής

                                                    </Button> */}
                                                </div>

                                            </Row>
                                            <Table className="align-items-center table-flush" responsive style={{ marginTop: '20px' }}>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">ΟΝΟΜΑ ΡΟΗΣ</th>
                                                        <th scope="col">ΕΝΕΡΓΕΙΕΣ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.flows.map((flow) =>
                                                        <tr key={flow.Name}>
                                                            <td>{flow.Name}</td>

                                                            <td>
                                                                <Button
                                                                    color="primary"
                                                                    href={"/basicLayout/flow/" + flow.Id}
                                                                    size="sm"
                                                                >
                                                                    Επεξεργασία Βημάτων
                                                                </Button>
                                                                <Button
                                                                    color="warning"
                                                                    href={"/basicLayout/testflow/" + flow.Id}
                                                                    size="sm"
                                                                >
                                                                    <i className="fas fa-pencil-alt" />
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    onClick={() => { this.deleteFlow(flow.Id) }}
                                                                    size="sm"
                                                                    title="Διαγραφή"
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>

                            <div className='push'></div>
                            <Modal
                                show={this.state.show}
                                onHide={() => this.setShow(false)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        ΠΡΟΣΘΗΚΗ ΝΕΑΣ ΡΟΗΣ
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-name"
                                                        >
                                                            Όνομα Ροής
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-flow-name'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-name"
                                                            name="flowName"
                                                            placeholder="Όνομα Πεδίου"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                        <ReactTooltip id={'info-flow-name'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-description"
                                                        >
                                                            Περιγραφή Ροής
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-flow-desc'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-description"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            name="flowDescription"
                                                            type="text"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                        <ReactTooltip id={'info-flow-desc'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-priority"
                                                        >
                                                            Προτεραιότητα Ροής
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-flow-priority'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-priority"
                                                            placeholder="Προτεραιότητα Ροής"
                                                            name="flowPriority"
                                                            type="number"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                        <ReactTooltip id={'info-flow-priority'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-startingDate"
                                                        >
                                                            Αρχική Ημ/νια Ροής
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-flow-start'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-startingDate"
                                                            name="startingDate"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            type="date"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                        <ReactTooltip id={'info-flow-start'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-endingDate"
                                                        >
                                                            Τελική Ημ/νια Ροής
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-flow-end'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-endingDate"
                                                            name="endingDate"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            type="date"
                                                            onChange={evt => this.handleChange(evt)}
                                                        />
                                                        <ReactTooltip id={'info-flow-end'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Τύπος Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-first-name"
                                                            type="select"
                                                            onChange={evt => this.updateType(evt)}
                                                        >
                                                            <option value="required">Υποχρεωτικό</option>
                                                            <option value="non-required">Μη Υποχρεωτικό</option>

                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Σειρά Πεδίου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            placeholder="Περιγραφή Πεδίου"
                                                            type="number"
                                                            onChange={evt => this.updateEmail(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                            </Row>
                                        </div>

                                    </Form>
                                    <div className="col text-right">
                                        <Button
                                            color="success"
                                            onClick={() => this.handleSubmit()}
                                            size="sm"
                                            disabled={!this.isFormValid()}
                                        >
                                            Ολοκλήρωση

                                        </Button>

                                    </div>
                                </Modal.Body>
                            </Modal>

                        </Container>

                    </>
                    :
                    <>
                    </>
                }


            </>
        );
    }

}

export default Flows;